<template>
  <sign-page
    title-text="医院岗位设置管理"
    :request="request"
    :column-list="columnList"
    @dataUpdate="dataUpdate"
    :dealFormData="dealFormData"
    table-size="large"
    :tableActionsFixed="true"
    :form-parms-add="formParmsAdd"
    :form-parms-update="formParmsUpdate"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  jobTitleRequest as request
} from '../../api'

export default {
  created () {
    this.$store.dispatch('loadJobTitleList')
  },
  methods: {
    dealFormData (data) {
      ['needYear', 'num', 'yTitle', 'needJobTitleYear'].forEach(v => {
        if (data[v] === '') {
          data[v] = null
        }
      })
      return data
    },
    dataUpdate () {
      this.$store.dispatch('loadJobTitleList', true)
    }
  },
  computed: {
    jobTitleTypes () {
      return this.$store.getters.selectDatas['job_title_type']
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '职称等级',
          key: 'title',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          selectDatas: this.jobTitleTypes,
          label: '岗位类型',
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          selectDatas: [{key: '初级', label: '初级'}, {key: '中级', label: '中级'}, {key: '高级', label: '高级'}],
          label: '岗位层级',
          key: 'topLevel'
        },
        {
          type: 'select',
          selectDatas: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}],
          label: '职称级别',
          key: 'mLevel'
        },
        {
          type: 'input',
          label: '等级',
          key: 'lelel',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'select',
          selectDatas: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}],
          label: '需要资格证书等级',
          key: 'needCertificateLevel'
        },
        {
          type: 'select',
          selectDatas: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}],
          label: '需要职称等级',
          key: 'needMLevel'
        },
        {
          type: 'input',
          label: '需要职称年限',
          key: 'needYear',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.jobTitleList,
          label: '需要岗位等级',
          key: 'needJobTitleId'
        },
        {
          type: 'input',
          label: '需要岗位年限',
          key: 'needJobTitleYear',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'input',
          label: '实际可用岗位数',
          key: 'num',
          check: {
            required: true,
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'input',
          label: '核准岗位数',
          key: 'yTitle',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        // {
        //   type: 'input',
        //   label: '医师',
        //   key: 'yTitle'
        // },
        // {
        //   type: 'input',
        //   label: '护理',
        //   key: 'hTitle'
        // },
        {
          type: 'input',
          label: '备注',
          key: 'remark'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '职称等级',
          sort: true,
          field: 'title',
          filterMethod (parm) {
            return parm.row.title === parm.value
          }
        },
        {
          title: '岗位类型',
          sort: true,
          field: 'type',
          filters: this.$store.getters.selectDatas['job_title_type'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位层级',
          sort: true,
          field: 'topLevel',
          filters: [{key: '初级', label: '初级'}, {key: '中级', label: '中级'}, {key: '高级', label: '高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '职称级别',
          sort: true,
          field: 'mLevel',
          filters: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '等级',
          sort: true,
          dataType: Number,
          field: 'lelel'
        },
        // {
        //   title: '需要资格证书',
        //   field: 'needCertificateTitle'
        // },
        {
          title: '需要资格证书等级',
          sort: true,
          field: 'needCertificateLevel',
          filters: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '需要职称等级',
          sort: true,
          field: 'needMLevel',
          filters: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '需要职称年限',
          sort: true,
          filterRange: true,
          dataType: Number,
          field: 'needYear',
          render: (h, rowData) => {
            return h('div', rowData && rowData.needYear && rowData.needYear > 100 ? '无限大' : (rowData.needYear ? rowData.needYear : '-'))
          }
        },
        {
          title: '需要岗位等级',
          sort: true,
          field: 'needJobTitleTitle',
          filterMethod (parm) {
            return parm.row.needJobTitleTitle === parm.value
          }
        },
        {
          title: '需要岗位年限',
          sort: true,
          dataType: Number,
          filterRange: true,
          field: 'needJobTitleYear',
          render: (h, rowData) => {
            return h('div', rowData && rowData.needJobTitleYear && rowData.needJobTitleYear > 100 ? '无限大' : (rowData.needJobTitleYear ? rowData.needJobTitleYear : '-'))
          }
        },
        {
          title: '实际可用岗位数',
          filterRange: true,
          sort: true,
          dataType: Number,
          field: 'num'
        },
        {
          title: '核准岗位数',
          filterRange: true,
          sort: true,
          dataType: Number,
          field: 'yTitle'
        },
        // {
        //   title: '医师',
        //   field: 'yTitle'
        // },
        {
          title: '排队标记',
          field: 'needQueue',
          render: (h, rowData) => {
            return h('div', rowData.needQueue ? '参与' : '不参与')
          },
          filters: ['参与', '不参与'].map(v => {
            return {
              label: v,
              value: v
            }
          }),
          filterMethod (parm) {
            if (parm.value === '__null') {
              return [undefined, '', null].includes(parm.row.needQueue)
            }
            if (parm.value === '参与') {
              return parm.row.needQueue && true
            } else {
              return !parm.row.needQueue && true
            }
          }
        },
        {
          title: '备注',
          sort: true,
          field: 'remark'
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
